.Pokecard {
	width: 20%;
	padding: 10px;
	margin: 1rem;
	box-shadow: 7px 10px 12px -5px rgba(0, 0, 0, 0.56);
	text-align: center;
	background-color: white;
	border-radius: 3px;
}

.Pokecard-data {
	font-size: 80%;
}

.Pokecard-title {
	margin-bottom: 1rem;
	font-weight: bold;
	font-size: 1.5rem;
}

.Pokecard-image {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 250px;
	background-color: #eceff1;
	border-radius: 3px;
	margin-bottom: 1rem;
}
.Pokecard img {
	transition: all ease 500ms;
}
.Pokecard img:hover {
	transform: scale(1.25);
	opacity: 0.7;
}
